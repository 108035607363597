<template>
  <div class="product-manage-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">编辑商品</span>
      </div>
      <div class="content">
        <el-tabs v-model="activeTab" tab-position="left" @tab-click="handleTabClick">
          <el-tab-pane label="选择类目" name="1">
            <div class="title">
              <span>选择编辑商品类目</span>
              <el-button type="primary" @click="step1Save">下一步</el-button>
            </div>
            <div class="tab-main">
              <el-form ref="formStep1" :model="formStep1" label-width="130px" :rules="rulesStep1">
                <el-form-item label="请选择商品类目:">
                  <div class="select-list">
                    <div class="select-item active">证书</div>
                  </div>
                </el-form-item>
                <el-form-item label="请选择明细:" prop="resourceId">
                  <el-select
                    v-model="formStep1.productAttributeCategoryId"
                    placeholder="请选择类目"
                    :disabled="!!productId || !!formStep2.productId"
                    @change="categoryChange"
                  >
                    <el-option
                      v-for="item in category"
                      :key="item.productAttributeCategoryId"
                      :value="item.productAttributeCategoryId"
                      :label="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-model="formStep1.resourceId"
                    placeholder="请选择证书"
                    :disabled="!!productId || !!formStep2.productId"
                  >
                    <el-option
                      v-for="item in listReview"
                      :key="item.certId"
                      :value="item.certId"
                      :label="item.certName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="商品信息" name="2" :disabled="!formStep2.productId">
            <div class="title">
              <span>商品信息</span>
              <el-button type="primary" @click="step2Save">下一步</el-button>
            </div>
            <div class="tab-main">
              <el-form ref="formStep2" :model="formStep2" label-width="120px" :rules="rulesStep2">
                <el-form-item label="商品类目:"
                  >证书-{{ step1Data.productAttributeCategoryName }}-{{ step1Data.resourceName }}
                </el-form-item>
                <el-form-item label="证书归属地:"
                  >{{ step1Data.provinceName }}-{{ step1Data.cityName }}</el-form-item
                >
                <el-form-item label="商品分类:" prop="productCertCategoryId">
                  <el-select v-model="formStep2.productCertCategoryId" placeholder="请选择商品分类">
                    <el-option
                      v-for="item in categoryList"
                      :key="item.productCategoryId"
                      :value="item.productCategoryId"
                      :label="item.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="商品名称:" prop="name">
                  <el-input
                    v-model="formStep2.name"
                    placeholder="请输入2 ~ 32个字的商品名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="商品特点:" prop="description">
                  <el-input
                    v-model="formStep2.description"
                    placeholder="请输入4 ~ 14个字的商品特点"
                  ></el-input>
                </el-form-item>
                <el-form-item ref="pic" label="商品封面图片:" prop="pic">
                  <upload-image v-model="formStep2.pic" :upload-img="$refs.pic"></upload-image>
                  <p class="tip">封面图片大小在5MB内</p>
                </el-form-item>
                <el-form-item ref="albumPics" label="商品相册:" prop="albumPics">
                  <UploadimageCard
                    v-model="imageList"
                    @FileList="handleGetImageList"
                  ></UploadimageCard>
                </el-form-item>
                <el-form-item ref="videoUrl" label="商品封面视频:" prop="videoUrl">
                  <div v-if="!formStep2.videoUrl">
                    <el-upload
                      action="/api/third/api/huaWeiUpload/upload/video/playUrl"
                      :on-success="handleUploadSuccess"
                      :file-list="fileVedioList"
                      :limit="1"
                      :on-remove="handleUploadFileRemove"
                      :before-upload="beforeAvatarUpload"
                      accept=".MP4"
                    >
                      <el-button>上传封面视频</el-button>
                      <p slot="tip" class="VideoTip">
                        视频支持.MP4格式，大小在20MB内
                      </p>
                    </el-upload>
                  </div>
                  <div v-if="formStep2.videoUrl" class="player">
                    <div class="playerVideo">
                      <video
                        id="playerVideo"
                        ref="media"
                        :src="formStep2.videoUrl"
                        controls="controls"
                        style="100%;height:100%;object-fit:fill"
                        :poster="videoImage"
                        class="playerVideo"
                      ></video>
                    </div>
                    <span @click="handleremoveVideo">删除重传</span>
                  </div>
                </el-form-item>
                <el-form-item label="商品介绍:" prop="detailHtml">
                  <tinymce ref="editor" v-model="formStep2.detailHtml"></tinymce>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="商品规格" name="3" :disabled="!step2Data.name">
            <div class="title">
              <span>商品规格列表</span>
              <el-button type="primary" @click="publish">确定发布</el-button>
            </div>
            <div class="tab-main">
              <div class="table">
                <div class="table-header">
                  <el-button type="primary" @click="addTableRow">添加</el-button>
                  <!-- <el-button>批量添加</el-button> -->
                </div>
                <el-table stripe :data="tableData" style="width: 100%">
                  <el-table-column
                    v-for="item in specList"
                    :key="item.productAttributeDictId"
                    :prop="item.productAttributeDictId + ''"
                    :label="item.name"
                    min-width="100"
                  >
                    <template slot="header"> <span>*</span>{{ item.name }}</template>
                    <template
                      v-if="scope.row.dynamicSkuAttrList[item.productAttributeDictId]"
                      slot-scope="scope"
                    >
                      <template v-if="scope.row.editable">
                        <el-select
                          v-if="item.inputType == 1"
                          v-model="
                            scope.row.dynamicSkuAttrList[item.productAttributeDictId]['value']
                          "
                          :placeholder="item.name"
                        >
                          <el-option
                            v-for="(option, index) in item.inputList"
                            :key="index"
                            :label="option"
                            :value="option"
                          ></el-option>
                        </el-select>
                        <el-input
                          v-else
                          v-model="
                            scope.row.dynamicSkuAttrList[item.productAttributeDictId]['value']
                          "
                          :placeholder="item.name"
                        ></el-input>
                      </template>
                      <span v-else>{{
                        scope.row.dynamicSkuAttrList[item.productAttributeDictId]['value']
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="price" label="价格(元)" align="right" min-width="85">
                    <template slot="header"> <span>*</span> 价格(元) </template>
                    <template slot-scope="scope">
                      <el-input-number
                        v-if="scope.row.editable"
                        v-model="scope.row.price"
                        style="width:100%"
                        placeholder="价格"
                        :controls="false"
                      ></el-input-number>
                      <span v-else>￥{{ scope.row.price }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="promotionPrice"
                    label="优惠价格(元)"
                    align="right"
                    min-width="130"
                  >
                    <template slot-scope="scope">
                      <el-input-number
                        v-if="scope.row.editable"
                        v-model="scope.row.promotionPrice"
                        :controls="false"
                        style="width:100%"
                        placeholder="优惠价格"
                      ></el-input-number>
                      <span v-else>
                        {{ scope.row.promotionPrice ? '￥' : ''
                        }}{{ scope.row.promotionPrice }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="publishStartTime" label="定时上架" min-width="150">
                    <template slot-scope="scope">
                      <span v-show="scope.row.publishStartTime" class="timeText"
                        >{{ scope.row.publishStartTime
                        }}<i
                          v-if="scope.row.editable"
                          class="el-icon-close"
                          @click="scope.row.publishStartTime = ''"
                        ></i
                      ></span>
                      <div
                        v-show="!scope.row.publishStartTime && scope.row.editable"
                        class="timePicker"
                      >
                        <span>设置时间</span>
                        <el-date-picker
                          v-model="scope.row.publishStartTime"
                          class="hiddenPicker"
                          type="datetime"
                          placeholder="选择日期时间"
                          value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="publishEndTime" label="定时下架" min-width="150">
                    <template slot-scope="scope">
                      <span v-show="scope.row.publishEndTime" class="timeText"
                        >{{ scope.row.publishEndTime
                        }}<i
                          v-if="scope.row.editable"
                          class="el-icon-close"
                          @click="scope.row.publishEndTime = ''"
                        ></i
                      ></span>
                      <div
                        v-show="!scope.row.publishEndTime && scope.row.editable"
                        class="timePicker"
                      >
                        <span>设置时间</span>
                        <el-date-picker
                          v-model="scope.row.publishEndTime"
                          class="hiddenPicker"
                          type="datetime"
                          placeholder="选择日期时间"
                          value-format="yyyy-MM-dd HH:mm:ss"
                        >
                        </el-date-picker>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                      <el-button
                        v-if="scope.row.editable"
                        size="mini"
                        type="text"
                        @click="handleSave(scope.$index)"
                      >
                        保存
                      </el-button>
                      <el-button v-else size="mini" type="text" @click="handleEdit(scope.$index)">
                        编辑
                      </el-button>
                      <el-button size="mini" type="text" @click="handleRemove(scope.$index)">
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!-- <div class="title">
                <span>发布上架设置</span>
              </div>
              <el-checkbox v-model="autoShelf">审核完成自动上架</el-checkbox> -->
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>
<script>
import UploadImage from '@/components/UploadImage'
import UploadimageCard from '@/components/UploadimageCard'
import Tinymce from '@/components/Tinymce'
import {
  productCertCategory,
  listReviewByCategory,
  productCertCreate,
  productCertInfoSave,
  productCertSpecList,
  productSkuSave,
  productSkuRemove,
  productCertInfo,
  productCertSubmit,
  certCategoryList,
} from '@/api/center'
import to from 'await-to'
export default {
  name: 'ProductManageAdd',
  components: { UploadImage, Tinymce, UploadimageCard },
  data() {
    return {
      productId: '',
      activeTab: '1',
      rulesStep1: {
        resourceId: [{ required: true, message: '请选择证书', trigger: 'change' }],
      },
      rulesStep2: {
        name: [
          { required: true, message: '请输入2 ~ 32个字的商品名称', trigger: 'blur' },
          { min: 2, max: 32, message: '请输入2 ~ 32个字的商品名称', trigger: 'blur' },
        ],
        productCertCategoryId: [{ required: true, message: '请输入商品分类', trigger: 'change' }],
        description: [
          { required: true, message: '请输入4 ~ 14个字的商品特点', trigger: 'blur' },
          { min: 4, max: 14, message: '请输入4 ~ 14个字的商品特点', trigger: 'blur' },
        ],
        pic: [{ required: true, message: '请上传商品封面图', trigger: 'blur' }],
        detailHtml: [{ required: true, message: '请输入商品介绍', trigger: 'blur' }],
      },
      formStep1: {
        resourceId: '',
        productAttributeCategoryId: '',
      },
      step1Data: {},
      step2Data: {},
      formStep2: {
        productCertCategoryId: '',
        name: '',
        description: '',
        pic: '',
        detailHtml: '',
        videoUrl: '',
        albumPics: [],
      },
      videoImage: '',
      imageList: [],
      fileVedioList: [],
      category: [],
      listReview: [],
      tableData: [],
      specList: [],
      autoShelf: false,
      loading: false,
      categoryList: [],
    }
  },
  mounted() {
    this.getProductCertCategory()
    if (this.$route.params.productId) {
      this.productId = this.$route.params.productId
      this.activeTab = '2'
      this.getProductCertInfo()
    }
    this.getCertCategory()
  },
  methods: {
    async getProductCertInfo() {
      const [res, err] = await to(productCertInfo({ productId: this.productId }))
      if (err) return this.$message.warning(err.msg)
      this.step1Data = res.data
      this.formStep1.productAttributeCategoryId = res.data.productAttributeCategoryId
      this.getListReviewByCategory(res.data.productAttributeCategoryId)
      this.formStep1.resourceId = res.data.resourceId
      this.formStep2.productId = res.data.productId
      this.formStep2.name = res.data.name
      this.formStep2.description = res.data.description
      this.formStep2.pic = res.data.pic
      this.formStep2.videoUrl = res.data.videoUrl
      this.formStep2.albumPics = res.data.albumPics
      this.formStep2.productCertCategoryId = res.data.productCategoryId
      this.imageList = this.formStep2.albumPics
      if (this.formStep2.videoUrl) {
        this.fileVedioList.push({ url: this.formStep2.videoUrl, name: '封面视频' })
        this.videoImage = this.formStep2.videoUrl + '?vframe/jpg/offset/1/w/500/h/300'
      }
      this.formStep2.detailHtml = res.data.detailHtml
      this.tableData = res.data.skuList
    },
    async getProductCertCategory() {
      const [res, err] = await to(productCertCategory({}))
      if (err) return this.$message.warning(err.msg)
      this.category = res.data
    },
    async getCertCategory() {
      const [res, err] = await to(certCategoryList({}))
      if (err) return this.$message.warning(err.msg)
      this.categoryList = res.data
    },
    async getListReviewByCategory(productAttributeCategoryId) {
      const [res, err] = await to(listReviewByCategory({ productAttributeCategoryId }))
      if (err) return this.$message.warning(err.msg)
      this.listReview = res.data
    },
    categoryChange(e) {
      this.formStep1.resourceId = ''
      this.getListReviewByCategory(e)
    },
    step1Save() {
      this.$refs['formStep1'].validate(async valid => {
        if (valid) {
          const [res, err] = await to(
            productCertCreate({ ...this.formStep1, productId: this.productId }),
          )
          if (err) return this.$message.warning(err.msg)
          this.activeTab = '2'
          this.step1Data = res.data
          this.formStep2.productId = res.data.productId
        }
      })
    },
    step2Save() {
      this.$refs['formStep2'].validate(async valid => {
        if (valid) {
          const [res, err] = await to(productCertInfoSave(this.formStep2))
          if (err) return this.$message.warning(err.msg)
          this.activeTab = '3'
          this.step2Data = res.data
          this.getProductCertSpecList()
        }
      })
    },
    handleTabClick() {},
    addTableRow() {
      let dynamicSkuAttrList = {}
      this.specList.map(item => {
        dynamicSkuAttrList[item.productAttributeDictId] = { value: '' }
      })
      this.tableData.push({
        editable: true,
        dynamicSkuAttrList,
      })
    },
    async handleSave(index) {
      if (this.loading) return
      for (const key in this.tableData[index].dynamicSkuAttrList) {
        if (Object.hasOwnProperty.call(this.tableData[index].dynamicSkuAttrList, key)) {
          const element = this.tableData[index].dynamicSkuAttrList[key]
          if (!element.value) {
            for (let i = 0; i < this.specList.length; i++) {
              const spec = this.specList[i]
              if (spec.productAttributeDictId == key) {
                return this.$message.warning('请填写' + spec.name)
              }
            }
          }
        }
      }
      if (!this.tableData[index].price) return this.$message.warning('请填写价格')
      if (!this.tableData[index].price > 999999) return this.$message.warning('价格不能超过999999')
      if (this.tableData[index].promotionPrice > 999999)
        return this.$message.warning('优惠价格不能超过999999')
      console.log(Number(this.tableData[index].promotionPrice), Number(this.tableData[index].price))
      if (Number(this.tableData[index].promotionPrice) > Number(this.tableData[index].price))
        return this.$message.warning('优惠价格不能大于价格')
      this.loading = true
      const [res, err] = await to(
        productSkuSave({ ...this.tableData[index], productId: this.formStep2.productId }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$set(this.tableData[index], 'editable', false)
      this.$set(this.tableData[index], 'productSkuId', res.data.productSkuId)
      this.$set(this.tableData[index], 'promotionPrice', res.data.promotionPrice)
      this.$set(this.tableData[index], 'price', res.data.price)
      this.$set(this.tableData[index], 'dynamicSkuAttrList', res.data.dynamicSkuAttrList)
    },
    handleEdit(index) {
      this.$set(this.tableData[index], 'editable', true)
    },
    async handleRemove(index) {
      this.$confirm('确认删除这项规则吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        if (this.tableData[index].productSkuId) {
          const [res, err] = await to(
            productSkuRemove({
              productSkuId: this.tableData[index].productSkuId,
              productId: this.formStep2.productId,
            }),
          )
          if (err) return this.$message.warning(err.msg)
          console.log(res)
        }
        this.tableData.splice(index, 1)
      })
    },
    async getProductCertSpecList() {
      const [res, err] = await to(productCertSpecList({ productId: this.formStep2.productId }))
      if (err) return this.$message.warning(err.msg)
      this.specList = res.data.specificationList
    },
    async publish() {
      for (let i = 0; i < this.tableData.length; i++) {
        const item = this.tableData[i]
        if (item.editable) {
          return this.$message.warning('有未保存的规则')
        }
      }

      this.$confirm('确认发布商品吗？等待商品审核后可以上架', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(productCertSubmit({ productId: this.formStep2.productId }))
        if (err) return this.$message.warning(err.msg)
        this.$router.push('/scenter/productManage')
      })
    },
    handleGetImageList(val) {
      let diffArry = []
      val.map(item => {
        diffArry.push(item.url)
      })
      this.imageList = []
      this.imageList = diffArry
      this.formStep2.albumPics = this.imageList
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 20
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 20MB!')
      }
      return isLt5M
    },
    handleremoveVideo() {
      this.formStep2.videoUrl = ''
      this.handleUploadFileRemove()
    },
    handleUploadFileRemove(file, fileList) {
      this.fileVedioList = fileList
    },
    handleUploadSuccess(response) {
      console.log(this.fileVedioList)
      this.formStep2.videoUrl = response.data.videoUploadUrl
      this.$message.success('上传成功')
    },
  },
}
</script>
<style lang="scss" scoped>
.product-manage-add {
  ::v-deep.el-tabs {
    .el-tabs__item {
      width: 180px;
      height: 60px;
      background: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 60px;
      color: #c9c9c9;
      text-align: center;
      border-right: 2px solid #f5f5f5;
      &.is-active {
        color: #ff7b33;
        background: #fcfbfa;
      }
    }
  }
  ::v-deep.el-card {
    .el-card__body {
      padding: 0;
    }
    .content {
      height: calc(100vh - 210px);
      .title {
        height: 78px;
        border-bottom: 1px solid #e2e2e2;
        font-size: 18px;
        font-weight: 500;
        line-height: 78px;
        color: #4d4e5d;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .el-form {
        .select-list {
          .select-item {
            width: 98px;
            height: 40px;
            line-height: 40px;
            background: #ff7b33;
            border-radius: 4px;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .el-select {
          width: 350px;
          margin-right: 24px;
        }
        .el-input {
          width: 350px;
        }
      }
      .table {
        margin-top: -20px;
        .table-header {
          height: 90px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .el-button + .el-button {
            margin-left: 25px;
          }
        }
        .el-table {
          th {
            background-color: #fafafa;
          }
          .hiddenPicker {
            cursor: pointer !important;
            position: absolute;
            left: 0;
            top: 12px;
            cursor: pointer;
            opacity: 0;
          }
          .timeText {
            i {
              cursor: pointer;
              font-size: 16px;
              color: #909399;
              margin-left: 6px;
            }
          }
          .timePicker {
            overflow: hidden;
            span {
              font-size: 14px;
              font-weight: 400;
              color: #ff7b33;
            }
            input {
              cursor: pointer;
            }
          }
        }
        .el-table__header {
          .cell {
            > span {
              color: #f67878;
            }
          }
        }
        .el-input__inner {
          text-align: left !important;
        }
      }
      .el-checkbox {
        margin-top: 20px;
      }
    }
  }
  ::v-deep.el-tabs {
    height: 100%;
    .el-tabs__header {
      height: 100%;
      padding: 20px;
      border-right: 1px solid #e2e2e2;
      margin-right: 0;
    }
    .el-tabs__nav-wrap::after {
      display: none;
    }
    .tab-main {
      height: calc(100vh - 290px);
      overflow: auto;
      padding: 20px;
      box-sizing: border-box;
    }
  }
  // ::v-deep.uploader {
  //   height: 120px;
  //   .el-upload {
  //     box-sizing: border-box;
  //     height: 120px;
  //     width: 160px;
  //   }
  //   .tip {
  //     font-size: 12px;
  //     line-height: 12px;
  //     color: #909399;
  //   }
  // }
  .tinymce-editor {
    width: 750px;
  }
  ::v-deep.el-form-item__content {
    width: 790px;
    .uploader {
      height: 120px;
      margin-bottom: 12px;
      .el-upload {
        box-sizing: border-box;
        height: 120px;
        width: 160px;
      }
    }
    .tip {
      font-size: 12px;
      line-height: 12px;
      color: #909399;
    }
  }
  .VideoTip {
    margin-top: 12px;
    font-size: 12px;
    line-height: 12px;
    color: #909399;
  }
  .player {
    display: flex;
    align-items: flex-end;
    .playerVideo {
      width: 500px;
      height: 300px;
    }
    span {
      margin-left: 20px;
      width: 110px;
      height: 40px;
      border-radius: 4px;
      border: 0;
      padding: 0;
      text-align: center;
      font-family: Noto Sans SC;
      font-weight: normal;
      font-size: 14px;
      background: #ff7b33;
      color: #ffffff;
      vertical-align: bottom;
      cursor: pointer;
    }
  }
}
</style>
